import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/socio/nuevo',
    name: 'newPartner',
    component: () => import('../views/NewPartnerView.vue')
  },
  {
    path: '/socios',
    name: 'partners',
    component: () => import('../views/PartnersView.vue')
  },
  {
    path: '/Estudiante/nuevo',
    name: 'newStudents',
    component: () => import('../views/StudentView.vue')
  },
  {
    path: '/Carnet/:id',
    name: 'carnet',
    component: () => import('../views/CarnetView.vue')
  },
  {
    path: '/validar/:id',
    name: 'validate',
    component: () => import('../views/ValidateView.vue')
  },
  {
    path: '/validarConyune/:id',
    name: 'validate',
    component: () => import('../views/ValidateSpouse.vue')
  },
  {
    path: '/validarHijo/:id',
    name: 'validate',
    component: () => import('../views/ValidateChildren.vue')
  },
  {
    path: '/socio/editar/:id',
    name: 'editPartner',
    component: () => import('../views/EditPartnerView.vue')
  },
  {
    path: '/socio/ficha/:id',
    name: 'dataSheet',
    component: () => import('../views/DataSheetView.vue')
  },
  {
    path: '/acceso',
    name: 'access',
    component: () => import('../views/AcesssView.vue')
  },
  {
    path: '/registro',
    name: 'register',
    component: () => import('../views/RegisterView.vue')
  },
  {
    path: '/cambiarcontrasena/:id',
    name: 'changePass',
    component: () => import('../views/ChangePassView.vue')
  },
  {
    path: '/descargar/:id',
    name: 'download',
    component: () => import('../views/DownloadCarnetVue.vue')
  },
  {
    path: '/descargarSpouse/:id',
    name: 'download',
    component: () => import('../views/DownloadCarnetSpouseVue.vue')
  },
  {
    path: '/descargarChild/:id',
    name: 'download',
    component: () => import('../views/DownloadCarnetChildVue.vue')
  },
  {
    path: '/usuarios',
    name: 'users',
    component: () => import('../views/UsersView.vue')
  },
  {
    path: '/usuario/nuevo',
    name: 'newUsers',
    component: () => import('../views/NewUserView.vue')
  },
  {
    path: '/usuario/editar/:id',
    name: 'editUsers',
    component: () => import('../views/EditUserView.vue')
  },
  {
    path: '/editarcontrasena/:id',
    name: 'CambiarPass',
    component: () => import('../views/CambiarContrasenaView.vue')
  },
  {
    path: '/errores',
    name: 'Errors',
    component: () => import('../views/LogErrorView.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
